import React, { useEffect, useState } from "react";
import "../../styles/contactUs/index.css";
import { ReactComponent as Mobile } from "../../images/contactUs/mobile.svg";
import { ReactComponent as Telephone } from "../../images/contactUs/telephone.svg";
import { ReactComponent as Fax } from "../../images/contactUs/fax.svg";
import { ReactComponent as PoBox } from "../../images/contactUs/po-box.svg";
import { ReactComponent as Email } from "../../images/contactUs/email.svg";
import { ReactComponent as Website } from "../../images/contactUs/website.svg";
import { useLocation } from "react-router-dom";

function ContactUs() {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    const element = document.getElementById("contactUs");
    if (element && location.hash === "#contactUs") {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    let timer;
    if (submitStatus === "success") {
      timer = setTimeout(() => {
        setSubmitResult(null);
        setSubmitStatus(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [submitStatus]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (submitStatus === "error") {
      setSubmitResult(null);
      setSubmitStatus(null);
    }
  };

  const validateForm = () => {
    if (formData.name.length === 0 || formData.name.length > 50) return false;
    if (
      formData.email.length === 0 ||
      formData.email.length > 50 ||
      !/\S+@\S+\.\S+/.test(formData.email)
    )
      return false;
    if (formData.message.length === 0 || formData.message.length > 5000)
      return false;
    return true;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSubmitResult("Please check your inputs and try again.");
      setSubmitStatus("error");
      return;
    }

    setIsSubmitting(true);
    setSubmitResult(null);
    setSubmitStatus(null);

    try {
      const response = await fetch(
        "https://api.connecttofit.com/external/email/thePalmsWebsite",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            details: formData.message
          })
        }
      );

      if (response.ok) {
        setSubmitResult("Message sent successfully!");
        setSubmitStatus("success");
        setFormData({ name: "", email: "", message: "" });
      } else {
        setSubmitResult("Failed to send message. Please try again later.");
        setSubmitStatus("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitResult("An error occurred. Please try again later.");
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=21.5858895,39.1452612&zoom=17&size=570x624&maptype=roadmap&markers=color:red%7C21.5858895,39.1452612&key=AIzaSyA7wgcU7ul0z_eD3L1VYX24OEfK-aI8vAY`;
  const mapLink = `https://www.google.com/maps/place/21%C2%B035'09.2%22N+39%C2%B008'42.9%22E/@21.5858895,39.1426863,17z/data=!3m1!4b1!4m4!3m3!8m2!3d21.5858895!4d39.1452612?entry=ttu`;

  return (
    <section className="contactUs-container" id="contactUs">
      <div className="common-container">
        <div className="contactUs-content">
          <div className="contactUs-header">
            <h1 className="contactUs-title">Get in Touch</h1>
            <p className="contactUs-description">
            Drop us a line.  We would love to hear from you!
            </p>
          </div>
          <div className="contactUs-main">
            <div className="contactUs-form-container">
              <form className="contactUs-form" onSubmit={sendEmail}>
                <div className="contactUs-form-group">
                  <label className="contactUs-form-label">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    className="contactUs-form-input"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter your name"
                  />
                </div>
                <div className="contactUs-form-group">
                  <label className="contactUs-form-label">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    className="contactUs-form-input"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="contactUs-form-group">
                  <label className="contactUs-form-label">
                    Message <span className="required">*</span>
                  </label>
                  <textarea
                    className="contactUs-form-textarea"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button
                  className="contactUs-form-submit"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Send Message"}
                </button>
                {submitResult && (
                  <p
                    className={`submit-result ${
                      submitStatus === "success" ? "success" : "error"
                    }`}
                  >
                    {submitResult}
                  </p>
                )}
              </form>
              <a
                href={mapLink}
                target="_blank"
                style={{ minWidth: "300px", flex: 1 }}
                rel="noopener noreferrer"
              >
                <img
                  className="contactUs-image"
                  src={mapUrl}
                  alt="Map location"
                />
              </a>
            </div>
            <div className="contactUs-info">
              <a
                href="tel:+966566626938"
                className="contactUs-info-item"
              >
                <div className="contactUs-info-icon">
                  <Telephone />
                </div>
                <div className="contactUs-info-text">
                    <div>Telephone</div>
                  <div className="contactUs-info-detail">+966 566 626 938</div>
                </div>
              </a>
              <div className="contactUs-info-item">
                <div className="contactUs-info-icon">
                  <PoBox />
                </div>
                <div className="contactUs-info-text">
                  <div className="contactUs-info-title">P.O Box</div>
                  <div className="contactUs-info-detail">2617 Jaddah - KSA</div>
                </div>
              </div>
              <a
                href="mailto:info@thepalmsracket.com"
                className="contactUs-info-item"
              >
                <div className="contactUs-info-icon">
                  <Email />
                </div>
                <div className="contactUs-info-text">
                    Email
                  <div className="contactUs-info-detail">
                    info@thepalmsracket.com
                  </div>
                </div>
                </a>
              <a
                href="https://thepalmsracket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="contactUs-info-item"
              >
                <div className="contactUs-info-icon">
                  <Website />
                </div>
                <div className="contactUs-info-text">
                    Website
                  <div className="contactUs-info-detail">
                    https://thepalmsracket.com
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
