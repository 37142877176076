import React, { useEffect, useState } from "react";
import "../../styles/programs/index.css";
import adultProgram from "../../images/programs/adult-program.png";
import juniorProgram from "../../images/programs/junior-program.png";
import functionalFitnessProgram from "../../images/programs/functional-fitness-program.png";

import { ReactComponent as RightArrowIcon } from "../../images/right-arrow.svg";
import { useLocation } from "react-router-dom";

function Services() {
  const location = useLocation();

  const [activeProgram, setActiveProgram] = useState("racketsSales");


  useEffect(() => {
    // Scroll to the section when the component mounts if the URL includes the anchor
    const element = document.getElementById("services");
    if (element && location.hash.includes("#services")) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // const activeProgram = location.hash.split("#")[2];
    // if (activeProgram) {
    //   setActiveProgram(activeProgram);
    // }
  }, [location]);
  return (
    <section className="programs-container" id="services">
      <div className="common-container">
        <div className="programs-header">
          <div className="programs-title">Our Services</div>
          <div className="programs-description">
           *Discover The Palms Racket  Stringing Service!*
          </div>
        </div>
        <div className="programs-content">
          <div className="programs-list">

            {/* racketsSales Program*/}
            <div className={`program-card ${activeProgram == "racketsSales"? "active" : ""}`} onClick={()=> setActiveProgram("racketsSales")}>
              <div className="program-info">
                <div className="program-icon">
                  <div className="program-number">01</div>
                </div>
                <div className="program-name">Racquets Sales</div>
              </div>
              <div className="program-arrow adults">
                <RightArrowIcon/>
              </div>
            </div>

            {/* Racket Stringing Program*/}
            <div className={`program-card ${activeProgram == "racketStringing"? "active" : ""}`} onClick={()=> setActiveProgram("racketStringing")}>
              <div className="program-info">
                <div className="program-icon junior-icon">
                  <div className="program-number">02</div>
                </div>
                <div className="program-name junior-name">Racket Stringing</div>
              </div>
              <div className="program-arrow">
                <RightArrowIcon/>
              </div>
            </div>

            {/* privateEvents Program*/}
            <div className={`program-card ${activeProgram == "privateEvents"? "active" : ""}`} onClick={()=> setActiveProgram("privateEvents")}>
              <div className="program-info">
                <div className="program-icon junior-icon">
                  <div className="program-number">03</div>
                </div>
                <div className="program-name junior-name">Private events</div>
              </div>
              <div className="program-arrow">
                <RightArrowIcon/>
              </div>
            </div>
          </div>
          {
            activeProgram == "racketsSales" &&
            <div className="program-details">
              <div className="details-title">Racquets Sales</div>
              <div className="details-content">
                {/* <img className="details-image" src={adultProgram} alt="Adult Program" /> */}
                  <div className="details-text">
                    <strong style={{ fontWeight: 700 }}>*Brand*</strong>The Palms is proud to be partners with Technifibre, sponsor of leading professionals like Dani Medvedev and Iga Swiatek 
                    <p>
                      <strong style={{ fontWeight: 700 }}>*Expertise *</strong>: Our coaches will advise you regarding the material, the weight and the
                      size of the grip of your racquet .

                    </p>
                  </div>
              </div>
            </div>
          }
          {
            activeProgram == "racketStringing" &&
            <div className="program-details">
              <div className="details-title">Racquet Stringing</div>
              <div className="details-content">
                {/* <img className="details-image" src={juniorProgram} alt="racketStringing Program" /> */}
                <div className="details-text">
                  <strong style={{ fontWeight: 700 }}>*Expertise and Precision*</strong>: Our team of experienced stringers uses the latest
                  techniques and equipment to ensure your racquet is strung to perfection.
                  <p>
                    <strong style={{ fontWeight: 700 }}>*Quality Materials*</strong>: We offer a wide range of high-quality strings from leading brands.
                    Whether you prefer natural gut, synthetic gut, or polyester strings.

                  </p>
                </div>
              </div>
            </div>
          }
          {
            activeProgram == "privateEvents" &&
            <div className="program-details">
              <div className="details-title">Private events</div>
              <div className="details-content">
                {/* <img className="details-image" src={functionalFitnessProgram} alt="Functional Fitness Program" /> */}
                <div className="details-text">
                  <strong style={{ fontWeight: 700 }}>*Family*</strong>: Our team can advise and support you to organize your private.
                  tournament, kids birthday...
                  <p>
                    <strong style={{ fontWeight: 700 }}>*Corporate*</strong>: Improve your team building around a customized tennis event.

                  </p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  );
}

export default Services;
