import React, { useEffect } from "react";
import "../../styles/aboutSection/index.css";
import { Link, useLocation } from "react-router-dom";

function About() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the section when the component mounts if the URL includes the anchor
    const element = document.getElementById("aboutUs");
    if (element && location.hash === "#aboutUs") {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  return (
    <section className="about-container" id="aboutUs">
      <div className="common-container">
        <div className="about-content">
          <div className="about-title">About The Palms</div>
          <div className="about-description">
          The Palms Racquet Club & Academy is establishing the leading racquet club and academy platform in Saudi Arabia. Our primary objective is to spread the love of racquet sports in a secure family-friendly environment. The Palms will strive to offer a consistently world-class experience, with facilities suitable for hosting international competitions and led by a deep team of experienced, accredited coaches. 

          <br/>
          <br/>

          Our kids, adult and elite training programs provide exceptional benefit to players and the community, offering solutions for all levels of competition and focusing on development and fun whilst also creating the competitive landscape that will allow present & future generations of players to excel. 

          <br/>
          <br/>

          The Palms is overseen by a deep and complementary group of partners who – combined – offer unparalleled experience and track record in business, leisure and specifically racquet sports and who draw upon a deep network of leading professionals, coaches and service providers.  

          <br/>
          <br/>
          
          We will look to create a comfortable, nurturing and family-oriented environment for the local community; one that actively gives back by consistently working with & supporting local charitable organizations and programs.<Link to="/AboutUs">... Read More</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
