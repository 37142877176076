import React, { useEffect } from "react";
import "../../styles/coaches/index.css";
import coach1 from "../../images/coaches/coach-1.png";
import coach2 from "../../images/coaches/coach-2.png";
import coach3 from "../../images/coaches/coach-3.png";
import coach4 from "../../images/coaches/coach-4.png";
import coach5 from "../../images/coaches/coach-5.png";
import { useLocation } from "react-router-dom";

const coaches = [
  { id: 1, label: "Due  diligence", name: "Ahmad Ibrahim", role: "Fitness Coach", image: coach1 },
  { id: 2, label: "National or international official certification", name: "Nina Smith", role: "Tennis Coach", image: coach2 },
  { id: 3, label: "Skills aligned with The Palms mission", name: "John Doe", role: "Swimming Coach", image: coach3 },
  { id: 4, label: "Experience", name: "Emily Johnson", role: "Yoga Instructor", image: coach4 },
  { id: 5, label: "Sport values", name: "Michael Lee", role: "Strength Coach", image: coach5 }
];

function Coaches() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the section when the component mounts if the URL includes the anchor
    const element = document.getElementById("coaches");
    if (element && location.hash === "#coaches") {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  return (
    <section className="coaches-container" id="coaches">
      <div className="common-container">
        <div className="coaches-content">
          <div className="coaches-header">
            <h2 className="coaches-title">Our Coaches</h2>
            <p className="coaches-description">
            Our coaches are  hired  according  a strict process:
            </p>
          </div>
          <div className="coaches-list">
            {coaches.map((coach, idx) => (
              <div key={coach.id} className="coach-card">
                <div className="number">0{idx + 1}</div>
                <div className="name">{coach.label}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="coaches-list">
            {coaches.map(coach => (
              <div key={coach.id} className="coach-card">
                <img className="coach-image" src={coach.image} alt={coach.name} />
                <div className="coach-info">
                  <h3 className="coach-name">{coach.name}</h3>
                  <p className="coach-role">{coach.role}</p>
                </div>
              </div>
            ))}
          </div> */}
          {/* <div className="navigation-arrows">
            <div className="arrow-left">
              <div className="arrow-icon"></div>
            </div>
            <div className="arrow-right">
              <div className="arrow-icon"></div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Coaches;
