import React, { useState } from "react";
import "../../styles/aboutUs/index.css";

function AboutUs() {
  const [isPlayersSectionOpen, setPlayersSectionOpen] = useState(false);
  const [isCommitmentsSectionOpen, setCommitmentsSectionOpen] = useState(false);
  const [isCoachesSectionOpen, setCoachesSectionOpen] = useState(false);
  const [isProgramsSectionOpen, setProgramsSectionOpen] = useState(false);
  const [isServicesSectionOpen, setServicesSectionOpen] = useState(false);

  const toggleSection = (section) => {
    switch (section) {
      case "players":
        setPlayersSectionOpen(!isPlayersSectionOpen);
        break;
      case "commitments":
        setCommitmentsSectionOpen(!isCommitmentsSectionOpen);
        break;
      case "coaches":
        setCoachesSectionOpen(!isCoachesSectionOpen);
        break;
      case "programs":
        setProgramsSectionOpen(!isProgramsSectionOpen);
        break;
      case "services":
        setServicesSectionOpen(!isServicesSectionOpen);
        break;
      default:
        break;
    }
  };

  return (
    <section className="aboutUs-container">
      <div className="common-container">
        <div className="aboutUs-header">
          <h1>About The Palms</h1>
        </div>
        <div className="aboutUs-content">
          <div className="aboutUs-section aboutUs-intro">
            <p>
            The Palms Racquet Club & Academy is establishing the leading racquet club and academy platform in Saudi Arabia. Our primary objective is to spread the love of racquet sports in a secure family-friendly environment. The Palms will strive to offer a consistently world-class experience, with facilities suitable for hosting international competitions and led by a deep team of experienced, accredited coaches. 

            <br/>
            <br/>

            Our kids, adult and elite training programs provide exceptional benefit to players and the community, offering solutions for all levels of competition and focusing on development and fun whilst also creating the competitive landscape that will allow present & future generations of players to excel. 

            <br/>
            <br/>

            The Palms is overseen by a deep and complementary group of partners who – combined – offer unparalleled experience and track record in business, leisure and specifically racquet sports and who draw upon a deep network of leading professionals, coaches and service providers.  

            <br/>
            <br/>

            We will look to create a comfortable, nurturing and family-oriented environment for the local community; one that actively gives back by consistently working with & supporting local charitable organizations and programs.
            </p>
			<div className="whyThePalms-header">
				Why The Palms Racquet Club & Academy?
			</div>
			<ul className="whyThePalms-list">
			<li>International standard quality courts & facilities (indoor & outdoor) available across multiple venues in Jeddah (and soon across the Kingdom of Saudi Arabia)</li>
			<li>Accredited, carefully selected coaches & team members</li>
			<li>Year-round Training Programs designed to systematically develop Players across levels & age-groups, including Elite Programs</li>
			<li>Founders who share a deep love of the game and who have played at national and international levels</li>
			</ul>
          </div>
		  <div className="aboutUs-section">
            <h2 onClick={() => toggleSection("programs")}>
              Programs
              <span
                className={`arrow ${isProgramsSectionOpen ? "open" : ""}`}
              />
            </h2>
            {isProgramsSectionOpen && (
				<>
					<hr/>
					<p>
						<strong>
							The Palms Racquet Club & Academy will be offering a broad range
							of programs:
						</strong>
						<br />Adult Programs <br />Junior
						Programs<br />Functional Fitness programs
					</p>
				</>
            )}
      </div>
		  <div className="aboutUs-section">
            <h2 onClick={() => toggleSection("services")}>
              Services
              <span
                className={`arrow ${isServicesSectionOpen ? "open" : ""}`}
              />
            </h2>
            {isServicesSectionOpen && (
				<>
					<hr/>
					<p>
						<strong>
							The Palms Racquet Club & Academy will be offering a broad range
							of services:
						</strong>
                  <br />1.Racquets Sales <br />2.Racquet Stringing <br />3.Private events <br />4.Tournaments (soon) <br />
                  5.Leagues (soon)
					</p>
				</>
            )}
      </div>
		  <div className="aboutUs-section">
            <h2 onClick={() => toggleSection("coaches")}>
              Coaches
              <span
                className={`arrow ${isCoachesSectionOpen ? "open" : ""}`}
              />
            </h2>
            {isCoachesSectionOpen && (
				<>
					<hr/>
					<p>
						<strong>
							Our coaches are hired according to a strict process:
						</strong>
						<br/> 1.
						Due diligence <br />2. National or international official
						certification <br />3. Skills aligned with The Palms mission <br />
						4. Experience <br />5. Sport values
					</p>
				</>
            )}
          </div>
          <div className="aboutUs-section">
            <h2 onClick={() => toggleSection("commitments")}>
              Commitments
              <span
                className={`arrow ${isCommitmentsSectionOpen ? "open" : ""}`}
              />
            </h2>
            {isCommitmentsSectionOpen && (
				<>
					<hr/>
					<p>
						<strong>Coaches commit to:</strong> <br />• Treat players with
						respect at all times and in all situations. Provide feedback
						that is positive and constructive in nature. <br />• Ensure the
						safety and welfare of the players always. <br />• Treat all
						players fairly regardless of gender, ethnicity, age, disability,
						athletic potential. <br />• Help nurture a sense of respect and
						collaboration amongst all members regardless of results. <br />
						• Respect the confidentiality of members. <br />• Encourage and
						facilitate players’ independence and responsibility for their
						own behaviour, performance, decisions and actions. <br />• Know
						and respect tennis rules, regulations and standards, and
						encourage players to do likewise.
					</p>
				</>
            )}
          </div>
		  <div className="aboutUs-section">
            <h2 onClick={() => toggleSection("players")}>
              Players & Members Commit to
              <span
                className={`arrow ${isPlayersSectionOpen ? "open" : ""}`}
              />
            </h2>
            {isPlayersSectionOpen && (
				<>
					<hr/>
					<p>
						• Treat coaches and team members with respect and kindness at
						all times and in all situations. <br />• Be punctual and come
						focused and ready to play and learn. <br />• Treat everyone
						fairly regardless of gender, ethnicity, age, athletic ability.{" "}
						<br />• Help ensure the safety and welfare of others always.{" "}
						<br />• Help nurture a sense of respect and collaboration
						amongst all members regardless of results. <br />• Be
						responsible for their own behaviour, performance, decisions and
						actions. <br />• Learn and respect tennis rules, regulations and
						standards. <br />• Accept and respect the role of officials in
						ensuring that competitions are conducted fairly and according
						to the rules. <br />• Take care of the facility, its equipment
						and its cleanliness and upkeep.
					</p>
				</>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
