import React, { useEffect } from "react";
import "../../styles/findClub/index.css";
import mobileFrame from "../../images/findClub/mobile-frame.png";
import courtGirl from "../../images/findClub/court-girl.png";
import zoomInGirl from "../../images/findClub/zoom-in-girl.png";
import appStore from "../../images/findClub/app-store.png";
import googlePlay from "../../images/findClub/google-play.png";
import { useLocation } from "react-router-dom";

function FindClub() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the section when the component mounts if the URL includes the anchor
    const element = document.getElementById("findClub");
    if (element && location.hash === "#findClub") {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  return (
    <section className="findClub-container" id="findClub">
      <div className="common-container">
        <div className="findClub-content">
          <div className="findClub-header-and-links">
            <div className="findClub-header">
              <div className="findClub-title">Book With Us</div>
              <div className="findClub-description">
              Please download the app for court rental.
              </div>
            </div>
            <div className="app-links">
              <a href="https://apps.apple.com/jo/app/the-palms/id6642670440" target="_blank">
                <img className="app-store" src={appStore} alt="App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.connecttofit.thepalm&pcampaignid=web_share" target="_blank">
                <img className="google-play" src={googlePlay} alt="Google Play" />
              </a>
            </div>
          </div>
          <div className="image-stack">
            <img className="court-man" src={courtGirl} alt="Court Man" />
            <img className="zoom-in-man" src={zoomInGirl} alt="Zoom In Man" />
            <img className="mobile-frame" src={mobileFrame} alt="Mobile Frame" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FindClub;
